<template>
    <div class="bg-light mt-3 px-md-4 px-3 py-2">
        <div>
            <div v-if="errors.length">
                <el-alert type="error">
                    <ul class="mb-0">
                        <li v-for="(error, index) in errors" v-bind:key="index">
                            {{ error.message }}
                        </li>
                    </ul>
                </el-alert>
            </div>

            <div id="form_output"></div>

            <el-collapse
                class="w-100 border-bottom-0 mb-3"
                v-model="activeNames"
            >
                <el-collapse-item name="1" class="div-filters">
                    <template slot="title">
                        <h5 class="pl-3">
                            <i class="el-icon-plus mr-2" />
                            Nuevo Archivo a asociar
                        </h5>
                    </template>

                    <el-form
                        id="local_form"
                        @click.prevent="submitForm('ruleForm')"
                        ref="ruleForm"
                        :rules="rules"
                        :model="pageLink"
                    >
                        <div
                            class="row w-100 mx-0 px-1 mb-4 d-flex justify-content-between align-items-end"
                        >
                            <el-form-item class="col-md-12 mb-0">
                                <label
                                    >Seleccione la página al que desea asociar
                                    archivo</label
                                >

                                <el-select
                                    v-model="pageLink.settingPageId"
                                    class="w-100"
                                    @change="getSettingsPages($event)"
                                >
                                    <el-option
                                        v-for="settingPage in settingsPages"
                                        :key="settingPage.id"
                                        :label="
                                            settingPage.setting.business_name +
                                            ' País:' +
                                            settingPage.setting.country.name +
                                            ' Lenguaje:' +
                                            settingPage.setting.language.name
                                        "
                                        :value="settingPage.id"
                                    >
                                    </el-option>
                                </el-select>

                                <hr />
                            </el-form-item>
                            <el-form-item
                                class="col-md-5 m-0"
                                prop="name"
                                id="name"
                            >
                                <label>Título</label>

                                <el-input
                                    v-model="pageLink.title"
                                    name="name"
                                    placeholder="Ingrese el título del archivo"
                                />
                            </el-form-item>

                            <el-form-item prop="fileList" id="fileList">
                                <!-- logo -->

                                <div
                                    class="d-flex justify-content-center"
                                    v-if="truthty(image)"
                                >
                                    <img
                                        :src="image"
                                        alt="Logo"
                                        style="max-height: 300px; width: 200px"
                                    />
                                </div>

                                <el-upload
                                    class="upload-demo text-center"
                                    action="#"
                                    :on-change="handleChange"
                                    :on-remove="handleRemove"
                                    :auto-upload="false"
                                    :limit="1"
                                    :on-exceed="handleExceed"
                                    :file-list="setting.fileList"
                                    accept="image/*"
                                >
                                    <el-button
                                        class="mt-2"
                                        size="small"
                                        type="primary"
                                    >
                                        Click para subir perfil
                                    </el-button>

                                    <div slot="tip" class="el-upload__tip">
                                        Tamaño máximo de la imagen 10MB
                                    </div>
                                </el-upload>
                            </el-form-item>

                            <div class="col pt-3 text-right">
                                <button
                                    @click.prevent="submitForm('ruleForm')"
                                    class="btn btn-info text-white"
                                >
                                    {{ isEdition ? "Actualizar" : "Guardar" }}
                                </button>
                            </div>
                        </div>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>

        <!-- ---------------------------------------------- -->

        <div class="row w-100 mx-0">
            <el-table
                :data="pagesLinks"
                v-loading="waiting"
                style="width: 100%; height: 100%"
                max-height="500"
                :default-sort="{ prop: 'id', order: 'ascending' }"
            >
                <el-table-column prop="id" label="Cód" width="90" sortable>
                </el-table-column>

                <el-table-column
                    prop="name"
                    label="Nombre Completo"
                    min-width="250"
                    sortable
                >
                </el-table-column>

                <el-table-column
                    prop="email"
                    label="Email"
                    min-width="250"
                    sortable
                >
                </el-table-column>

                <el-table-column
                    class-name="item-center"
                    fixed="right"
                    label="Acciones"
                    min-width="100"
                >
                    <template slot-scope="props">
                        <div class="row d-flex justify-content-center">
                            <div class="col-md-4 m-0 mb-2 p-0">
                                <el-button
                                    size="small"
                                    @click="edit(props.row, props.$index)"
                                    :type="
                                        isEdition && props.$index == index
                                            ? 'warning'
                                            : 'primary'
                                    "
                                    :icon="
                                        isEdition && props.$index == index
                                            ? 'el-icon-close'
                                            : 'el-icon-edit'
                                    "
                                />
                            </div>

                            <div class="col-md-4 m-0 p-0">
                                <el-button
                                    size="small"
                                    @click="
                                        deleteCorporative(
                                            props.row,
                                            props.$index
                                        )
                                    "
                                    type="danger"
                                    icon="el-icon-delete"
                                ></el-button>
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    props: ["pagesLinks", "settingsPages"],
    data() {
        let checkMailFormat = (rule, value, callback) => {
            if (!this.isEmailValid(value)) {
                return callback(new Error("Formato de email incorrecto"));
            }
            return callback();
        };
        return {
            activeNames: [],
            pageLink: {
                settingPageId: 0,
                title: "",
                file: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "Debe escribir el nombre del usuario",
                        trigger: ["change", "blur"],
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "Debe escribir el email del usuario",
                        trigger: ["change", "blur"],
                    },
                    { validator: checkMailFormat, trigger: "blur" },
                ],
            },
            corporativesList: this.corporatives,
            isEdition: false,
            index: null,
            errors: [],
            buscar: "",
            waiting: false,
            setting_id: 0,
        };
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid, rules) => {
                if (valid) {
                    this.submit();
                } else {
                    this.goToError(rules);
                }
            });
        },
        edit(row, index) {
            const { id, name, email } = row;
            if (this.isEdition && index == this.index) {
                this.isEdition = false;
                this.clearForm();
                this.index = null;
                return;
            } else {
                this.isEdition = true;
                this.pageLink = {
                    settingPageId,
                    title,
                    file,
                };
                this.index = index;
            }
        },
        changeEstate(row, index) {
            this.pageLink = row;
            this.index = index;
            this.submitEdit();
        },
        submit() {
            this.errors = [];
            if (this.isEdition) {
                this.submitEdit();
            } else {
                this.submitCreate();
            }
        },
        handleChange($e) {
            this.image = URL.createObjectURL($e.raw);
            this.pageLink.file.push($e.raw);
            this.$refs["ruleForm"].validateField("fileList");
        },
        handleRemove(file, fileList) {
            this.pageLink.file = fileList;
            this.image = null;
            this.$refs["ruleForm"].validateField("fileList");
        },
        handleExceed(files, fileList) {
            this.$message.warning(`Solo puedes subir 1 logo.`);
        },

        deleteCorporative(row, index) {
            this.waiting = true;
            const url = `${window.location.origin}/corporatives/${row.id}`;
            axios.delete(url, this.pageLink).then((res) => {
                this.corporativesList.splice(index, 1);
                this.waiting = false;
                // [this.index] = res.data.person;
                this.isEdition = false;
                // this.clearForm();
                this.$message({
                    message: res.data.message,
                    type: "success",
                }).catch((e) => {
                    this.waiting = false;
                });
            });
        },
        submitCreate() {
            const url = `${window.location.origin}/corporatives`;
            this.waiting = true;
            axios
                .post(url, this.pageLink)
                .then((res) => {
                    this.waiting = false;
                    this.corporativesList.push(res.data.person);
                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    if (this.truthty(error.response.data)) {
                        this.errors = [];
                        if (this.truthty(error.response.data.server)) {
                            this.$message({
                                message: error.response.data.message,
                                type: "error",
                            });
                        } else {
                            error.response.data.forEach((row) =>
                                this.errors.push({ message: row })
                            );
                        }
                    }
                    // this.error = 1;
                });
        },
        submitEdit() {
            const url = `${window.location.origin}/corporatives/${this.pageLink.id}`;
            this.waiting = true;
            axios
                .put(url, this.pageLink)
                .then((res) => {
                    this.waiting = false;
                    const index = this.index;
                    this.corporativesList[index] = res.data.person;
                    this.isEdition = false;
                    this.clearForm();
                    this.$message({
                        message: res.data.message,
                        type: "success",
                    });
                })
                .catch((error) => {
                    this.waiting = false;
                    if (this.truthty(error.response.data)) {
                        this.errors = [];
                        if (this.truthty(error.response.data.server)) {
                            this.$message({
                                message: error.response.data.message,
                                type: "error",
                            });
                        } else {
                            error.response.data.forEach((row) =>
                                this.errors.push({ message: row })
                            );
                        }
                    }
                    // this.error = 1;
                });
        },
        clearForm() {
            this.pageLink = {
                settingPageId: 0,
                title: "",
                file: "",
            };
            this.$refs["ruleForm"].resetFields();
        },
    },
    mounted() {
        if (this.checkMobile() == false) {
            this.activeNames = ["1"];
        }
    },
};
</script>
